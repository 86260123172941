/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

:root {
  --srns-0: 0;
  --srns-1: 1;
  --srns-2: 2;
  --srns-4: 4;
  --srns-6: 6;
  --srns-8: 8;
  --srns-10: 10;
  --srns-12: 12;
  --srns-14: 14;
  --srns-16: 16;
  --srns-18: 18;
  --srns-20: 20;
  --srns-24: 24;
  --srns-32: 32;
  --srns-36: 36;
  --srns-40: 40;
  --srns-48: 48;
  --srns-56: 56;
  --srns-64: 64;
  --srns-72: 72;
  --srns-80: 80;
  --srns-88: 88;
  --srns-96: 96;
  --srns-104: 104;
  --srns-112: 112;
  --srns-120: 120;
  --srns-128: 128;
  --srns-136: 136;
  --srns-144: 144;
  --srns-152: 152;
  --srns-160: 160;
  --srns-168: 168;
  --srns-176: 176;
  --srns-184: 184;
  --srns-192: 192;
  --srns-200: 200;
  --srns-208: 208;
  --srns-216: 216;
  --srns-224: 224;
  --srns-232: 232;
  --srns-240: 240;
  --srns-248: 248;
  --srns-256: 256;
  --srns-05: 0.5;
  --srns-font-family-degular-mono: 'Degular Mono', monospace;
  --srns-font-family-degular-display: 'Degular Display', serif;
  --srns-font-family-degular-text: 'Degular Text', sans-serif;
  --srns-font-size-10: 10px;
  --srns-font-size-12: 12px;
  --srns-font-size-14: 14px;
  --srns-font-size-16: 16px;
  --srns-font-size-18: 18px;
  --srns-font-size-20: 20px;
  --srns-font-size-24: 24px;
  --srns-font-size-32: 32px;
  --srns-font-size-40: 40px;
  --srns-font-size-48: 48px;
  --srns-font-size-56: 56px;
  --srns-font-size-64: 64px;
  --srns-font-size-72: 72px;
  --srns-line-height-12: 12px;
  --srns-line-height-16: 16px;
  --srns-line-height-20: 20px;
  --srns-line-height-24: 24px;
  --srns-line-height-32: 32px;
  --srns-line-height-40: 40px;
  --srns-line-height-48: 48px;
  --srns-line-height-56: 56px;
  --srns-line-height-64: 64px;
  --srns-line-height-72: 72px;
  --srns-font-weight-400: 400;
  --srns-font-weight-500: 500;
  --srns-font-weight-600: 600;
  --srns-letter-spacing-0: 0;
  --srns-letter-spacing-020: 0.2px;
  --srns-letter-spacing-024: 0.24px;
  --srns-letter-spacing-036: 0.36px;
  --srns-letter-spacing-040: 0.4px;
  --srns-letter-spacing-048: 0.48px;
  --srns-letter-spacing-056: 0.56px;
  --srns-letter-spacing-064: 0.64px;
  --srns-letter-spacing-072: 0.72px;
  --srns-letter-spacing-080: 0.8px;
  --srns-letter-spacing-096: 0.96px;
  --srns-letter-spacing-112: calc(112px / 100);
  --srns-letter-spacing-128: calc(128px / 100);
  --srns-letter-spacing-144: calc(144px / 100);
  --srns-typography-public-h0-font-family: 'Degular Display', serif;
  --srns-typography-public-h0-font-weight: 600;
  --srns-typography-public-h0-line-height: 56px;
  --srns-typography-public-h0-font-size: 56px;
  --srns-typography-public-h0-letter-spacing: calc(112px / 100);
  --srns-typography-public-h1-font-family: 'Degular Display', serif;
  --srns-typography-public-h1-font-weight: 600;
  --srns-typography-public-h1-line-height: 32px;
  --srns-typography-public-h1-font-size: 32px;
  --srns-typography-public-h1-letter-spacing: 0.64px;
  --srns-typography-public-h2-font-family: 'Degular Display', serif;
  --srns-typography-public-h2-font-weight: 600;
  --srns-typography-public-h2-line-height: 24px;
  --srns-typography-public-h2-font-size: 24px;
  --srns-typography-public-h2-letter-spacing: 0.48px;
  --srns-typography-public-h3-font-family: 'Degular Display', serif;
  --srns-typography-public-h3-font-weight: 400;
  --srns-typography-public-h3-line-height: 24px;
  --srns-typography-public-h3-font-size: 20px;
  --srns-typography-public-h3-letter-spacing: 0.4px;
  --srns-typography-public-h3-strong-font-family: 'Degular Display', serif;
  --srns-typography-public-h3-strong-font-weight: 600;
  --srns-typography-public-h3-strong-line-height: 24px;
  --srns-typography-public-h3-strong-font-size: 20px;
  --srns-typography-public-h3-strong-letter-spacing: 0.4px;
  --srns-typography-public-paragraph-font-family: 'Degular Text', sans-serif;
  --srns-typography-public-paragraph-font-weight: 400;
  --srns-typography-public-paragraph-line-height: 24px;
  --srns-typography-public-paragraph-font-size: 18px;
  --srns-typography-public-paragraph-letter-spacing: calc(0.36px * -1);
  --srns-typography-public-paragraph-strong-font-family: 'Degular Text', sans-serif;
  --srns-typography-public-paragraph-strong-font-weight: 600;
  --srns-typography-public-paragraph-strong-line-height: 24px;
  --srns-typography-public-paragraph-strong-font-size: 18px;
  --srns-typography-public-paragraph-strong-letter-spacing: calc(0.36px * -1);
  --srns-typography-public-caption-font-family: 'Degular Text', sans-serif;
  --srns-typography-public-caption-font-weight: 400;
  --srns-typography-public-caption-line-height: 24px;
  --srns-typography-public-caption-font-size: 16px;
  --srns-typography-public-caption-letter-spacing: calc(0.64px * -1);
  --srns-typography-public-caption-strong-font-family: 'Degular Text', sans-serif;
  --srns-typography-public-caption-strong-font-weight: 600;
  --srns-typography-public-caption-strong-line-height: 24px;
  --srns-typography-public-caption-strong-font-size: 16px;
  --srns-typography-public-caption-strong-letter-spacing: calc(0.64px * -1);
  --srns-typography-public-footnote-font-family: 'Degular Text', sans-serif;
  --srns-typography-public-footnote-font-weight: 500;
  --srns-typography-public-footnote-line-height: 24px;
  --srns-typography-public-footnote-font-size: 14px;
  --srns-typography-public-footnote-letter-spacing: 0;
  --srns-typography-public-footnote-strong-font-family: 'Degular Text', sans-serif;
  --srns-typography-public-footnote-strong-font-weight: 600;
  --srns-typography-public-footnote-strong-line-height: 24px;
  --srns-typography-public-footnote-strong-font-size: 14px;
  --srns-typography-public-footnote-strong-letter-spacing: 0;
  --srns-typography-public-micro-font-family: 'Degular Text', sans-serif;
  --srns-typography-public-micro-font-weight: 400;
  --srns-typography-public-micro-line-height: 16px;
  --srns-typography-public-micro-font-size: 12px;
  --srns-typography-public-micro-letter-spacing: 0;
  --srns-typography-h0-font-family: 'Degular Display', serif;
  --srns-typography-h0-font-weight: 600;
  --srns-typography-h0-line-height: 56px;
  --srns-typography-h0-font-size: 56px;
  --srns-typography-h0-letter-spacing: calc(112px / 100);
  --srns-typography-h1-font-family: 'Degular Display', serif;
  --srns-typography-h1-font-weight: 600;
  --srns-typography-h1-line-height: 32px;
  --srns-typography-h1-font-size: 32px;
  --srns-typography-h1-letter-spacing: 0.64px;
  --srns-typography-h2-font-family: 'Degular Display', serif;
  --srns-typography-h2-font-weight: 600;
  --srns-typography-h2-line-height: 24px;
  --srns-typography-h2-font-size: 24px;
  --srns-typography-h2-letter-spacing: 0.48px;
  --srns-typography-h3-font-family: 'Degular Display', serif;
  --srns-typography-h3-font-weight: 600;
  --srns-typography-h3-line-height: 24px;
  --srns-typography-h3-font-size: 20px;
  --srns-typography-h3-letter-spacing: 0.4px;
  --srns-typography-paragraph-font-family: 'Degular Text', sans-serif;
  --srns-typography-paragraph-font-weight: 400;
  --srns-typography-paragraph-line-height: 24px;
  --srns-typography-paragraph-font-size: 18px;
  --srns-typography-paragraph-letter-spacing: calc(0.36px * -1);
  --srns-typography-paragraph-strong-font-family: 'Degular Text', sans-serif;
  --srns-typography-paragraph-strong-font-weight: 600;
  --srns-typography-paragraph-strong-line-height: 24px;
  --srns-typography-paragraph-strong-font-size: 18px;
  --srns-typography-paragraph-strong-letter-spacing: calc(0.36px * -1);
  --srns-typography-caption-font-family: 'Degular Text', sans-serif;
  --srns-typography-caption-font-weight: 400;
  --srns-typography-caption-line-height: 24px;
  --srns-typography-caption-font-size: 16px;
  --srns-typography-caption-letter-spacing: calc(0.64px * -1);
  --srns-typography-caption-strong-font-family: 'Degular Text', sans-serif;
  --srns-typography-caption-strong-font-weight: 600;
  --srns-typography-caption-strong-line-height: 24px;
  --srns-typography-caption-strong-font-size: 16px;
  --srns-typography-caption-strong-letter-spacing: calc(0.64px * -1);
  --srns-typography-footnote-font-family: 'Degular Text', sans-serif;
  --srns-typography-footnote-font-weight: 500;
  --srns-typography-footnote-line-height: 24px;
  --srns-typography-footnote-font-size: 14px;
  --srns-typography-footnote-letter-spacing: 0;
  --srns-typography-footnote-strong-font-family: 'Degular Text', sans-serif;
  --srns-typography-footnote-strong-font-weight: 600;
  --srns-typography-footnote-strong-line-height: 24px;
  --srns-typography-footnote-strong-font-size: 14px;
  --srns-typography-footnote-strong-letter-spacing: 0;
  --srns-typography-micro-font-family: 'Degular Text', sans-serif;
  --srns-typography-micro-font-weight: 500;
  --srns-typography-micro-line-height: 16px;
  --srns-typography-micro-font-size: 12px;
  --srns-typography-micro-letter-spacing: 0;
  --srns-spacing-0: 0;
  --srns-spacing-4xs: 2px;
  --srns-spacing-3xs: 4px;
  --srns-spacing-2xs: 6px;
  --srns-spacing-xs: 8px;
  --srns-spacing-sm: 12px;
  --srns-spacing-md: 16px;
  --srns-spacing-lg: 24px;
  --srns-spacing-xl: 32px;
  --srns-spacing-2xl: 40px;
  --srns-spacing-3xl: 48px;
  --srns-spacing-4xl: 64px;
  --srns-spacing-5xl: 80px;
  --srns-spacing-6xl: 96px;
  --srns-spacing-7xl: 112px;
  --srns-border-radius-0: 0;
  --srns-border-radius-4xs: 2px;
  --srns-border-radius-3xs: 4px;
  --srns-border-radius-2xs: 6px;
  --srns-border-radius-xs: 8px;
  --srns-border-radius-sm: 12px;
  --srns-border-radius-md: 16px;
  --srns-border-radius-lg: 24px;
  --srns-border-radius-xl: 32px;
  --srns-border-radius-2xl: 40px;
  --srns-border-radius-3xl: 48px;
  --srns-border-radius-circle: 9999px;
  --srns-opacity-0: 0;
  --srns-opacity-8: 8%;
  --srns-opacity-16: 16%;
  --srns-opacity-24: 24%;
  --srns-opacity-32: 32%;
  --srns-opacity-40: 40%;
  --srns-opacity-48: 48%;
  --srns-opacity-56: 56%;
  --srns-opacity-64: 64%;
  --srns-opacity-72: 72%;
  --srns-opacity-80: 80%;
  --srns-opacity-88: 88%;
  --srns-opacity-96: 96%;
  --srns-opacity-100: 1;
  --srns-time-0: 0;
  --srns-time-150: 150ms;
  --srns-time-300: 300ms;
  --srns-time-500: 500ms;
  --srns-time-700: 700ms;
  --srns-border-width-0: 0;
  --srns-border-width-05: 0.5px;
  --srns-border-width-1: 1px;
  --srns-border-width-2: 2px;
  --srns-border-width-4: 4px;
  --srns-elevation-0: 0 0 0 0 transparent;
  --srns-breakpoint-sm: 0;
  --srns-breakpoint-md: 601px;
  --srns-breakpoint-lg: 1025px;
  --srns-breakpoint-xl: 1441px;
  --srns-media-query-sm: (max-width: calc(601px - 1px));
  --srns-media-query-md: (min-width: calc(601px - 1px)) and (max-width: calc(1025px - 1px));
  --srns-media-query-lg: (min-width: calc(1025px - 1px)) and (max-width: calc(1441px - 1px));
  --srns-media-query-xl: (min-width: calc(1441px - 1px));
  --srns-media-query-gt-sm: (min-width: 601px);
  --srns-media-query-gt-md: (min-width: 1025px);
  --srns-media-query-gt-lg: (min-width: 1441px);
  --srns-media-query-lt-md: (max-width: calc(601px - 1px));
  --srns-media-query-lt-lg: (max-width: calc(1025px - 1px));
  --srns-media-query-lt-xl: (max-width: calc(1441px - 1px));
}

.srns-gesto-light {
  --srns-color-grey-10: #ffffff;
  --srns-color-grey-11: #5a7471;
  --srns-color-grey-20: #f7f8f8;
  --srns-color-grey-30: #d8e4e4;
  --srns-color-grey-40: #c6d4d2;
  --srns-color-grey-50: #a3b7b5;
  --srns-color-grey-60: #90a9a6;
  --srns-color-grey-70: #678582;
  --srns-color-grey-80: #5a7471;
  --srns-color-grey-90: #4d6361;
  --srns-color-grey-100: #405351;
  --srns-color-grey-110: #334241;
  --srns-color-grey-120: #243836;
  --srns-color-grey-08: #90a9a6;
  --srns-color-grey-06: #a3b7b5;
  --srns-color-grey-04: #c6d4d2;
  --srns-color-grey-02: #d8e4e4;
  --srns-color-lighter: #ffffff;
  --srns-color-darker: #334241;
  --srns-color-neutral-10: #ffffff;
  --srns-color-neutral-20: #f7f8f8;
  --srns-color-neutral-30: #d8e4e4;
  --srns-color-neutral-40: #c6d4d2;
  --srns-color-neutral-50: #a3b7b5;
  --srns-color-neutral-60: #90a9a6;
  --srns-color-neutral-70: #678582;
  --srns-color-neutral-80: #5a7471;
  --srns-color-neutral-90: #4d6361;
  --srns-color-neutral-100: #405351;
  --srns-color-neutral-110: #334241;
  --srns-color-neutral-120: #243836;
  --srns-color-accent-10: #e2fddb;
  --srns-color-accent-20: #cbfcbe;
  --srns-color-accent-30: #b4fba3;
  --srns-color-accent-40: #9cfa87;
  --srns-color-accent-50: #87f96c;
  --srns-color-accent-60: #529540;
  --srns-color-accent-70: #36632b;
  --srns-color-accent-80: #365d23;
  --srns-color-primary-10: #f2f4f2;
  --srns-color-primary-20: #c3cec1;
  --srns-color-primary-30: #668160;
  --srns-color-primary-40: #365a2f;
  --srns-color-primary-50: #083400;
  --srns-color-primary-60: #072900;
  --srns-color-primary-70: #041f00;
  --srns-color-primary-80: #031400;
  --srns-color-green-10: #227251;
  --srns-color-green-20: #c8eede;
  --srns-color-green-40: #7ad7af;
  --srns-color-green-60: #419f77;
  --srns-color-green-80: #227251;
  --srns-color-green-08: #419f77;
  --srns-color-green-06: #7ad7af;
  --srns-color-green-04: #c8eede;
  --srns-color-green-02: #c8eede;
  --srns-color-yellow-10: #d05e06;
  --srns-color-yellow-20: #ffdf99;
  --srns-color-yellow-40: #ffca5c;
  --srns-color-yellow-60: #ffab00;
  --srns-color-yellow-80: #d05e06;
  --srns-color-yellow-08: #ffab00;
  --srns-color-yellow-06: #ffca5c;
  --srns-color-yellow-04: #ffdf99;
  --srns-color-yellow-02: #ffdf99;
  --srns-color-blue-20: #cce7ff;
  --srns-color-blue-40: #66b8ff;
  --srns-color-blue-60: #007aff;
  --srns-color-blue-80: #004299;
  --srns-color-red-20: #ffe2d4;
  --srns-color-red-40: #ffc0a8;
  --srns-color-red-60: #ff5630;
  --srns-color-red-80: #d01406;
  --srns-elevation-xxs: inset 0 0.5px 0 0 rgb(from #041f00 r g b / 24%),
    inset 0 -0.5px 0 0 rgb(from #041f00 r g b / 32%), 0 0 8px -2px rgb(from #041f00 r g b / 8%),
    0 0 2px 0 rgb(from #041f00 r g b / 24%), 0 2px 2px -4px rgb(from #041f00 r g b / 64%);
  --srns-elevation-xs: inset 0 0.5px 0 0 rgb(from #041f00 r g b / 24%),
    inset 0 -0.5px 0 0 rgb(from #041f00 r g b / 32%), 0 4px 16px -2px rgb(from #041f00 r g b / 8%),
    0 0 2px 0 rgb(from #041f00 r g b / 24%), 0 2px 8px -4px rgb(from #041f00 r g b / 32%);
  --srns-elevation-sm: inset 0 0.5px 0 0 rgb(from #041f00 r g b / 24%),
    inset 0 -0.5px 0 0 rgb(from #041f00 r g b / 32%), 0 0 16px -2px rgb(from #041f00 r g b / 8%),
    0 0 8px -2px rgb(from #041f00 r g b / 24%), 0 12px 8px -12px rgb(from #041f00 r g b / 24%);
  --srns-elevation-md: inset 0 0.5px 0 0 rgb(from #041f00 r g b / 24%),
    inset 0 -0.5px 0 0 rgb(from #041f00 r g b / 32%), 0 0 16px -2px rgb(from #041f00 r g b / 8%),
    0 0 16px -4px rgb(from #041f00 r g b / 24%), 0 16px 12px -16px rgb(from #041f00 r g b / 16%);
  --srns-elevation-lg: inset 0 0.5px 0 0 rgb(from #041f00 r g b / 24%),
    inset 0 -0.5px 0 0 rgb(from #041f00 r g b / 32%), 0 0 16px -2px rgb(from #041f00 r g b / 8%),
    0 0 24px -4px rgb(from #041f00 r g b / 16%), 0 16px 24px -16px rgb(from #041f00 r g b / 16%);
  --srns-elevation-xl: inset 0 0.5px 0 0 rgb(from #041f00 r g b / 24%),
    inset 0 -0.5px 0 0 rgb(from #041f00 r g b / 32%), 0 0 16px -2px rgb(from #041f00 r g b / 8%),
    0 0 32px -8px rgb(from #041f00 r g b / 16%), 0 32px 32px -24px rgb(from #041f00 r g b / 16%);
  --srns-elevation-special: 0 0 8px 0 #083400, 0 0 0 4px rgb(from #083400 r g b / 8%),
    0 0 0 8px rgb(from #083400 r g b / 8%);
  --srns-color-minttea-20: #cafacb;
  --srns-color-minttea-40: #179a92;
  --srns-color-minttea-60: #003b2e;
  --srns-color-minttea-80: #061a1d;
  --srns-color-mental-20: #f1d6ff;
  --srns-color-mental-40: #ce8ef2;
  --srns-color-mental-60: #ae4fdd;
  --srns-color-mental-80: #7a2c9d;
  --srns-color-nutrition-20: #ccf5e0;
  --srns-color-nutrition-40: #83a8a4;
  --srns-color-nutrition-60: #225559;
  --srns-color-nutrition-80: #1f4042;
  --srns-color-accent: #87f96c;
  --srns-color-primary: #083400;
  --srns-color-white: #ffffff;
  --srns-color-black: #243836;
  --srns-color-transparent: transparent;
  --srns-color-success: #419f77;
  --srns-color-info: #007aff;
  --srns-color-warning: #ffab00;
  --srns-color-error: #ff5630;
  --srns-color-red: #ff5630;
  --srns-color-green: #419f77;
  --srns-color-yellow: #ffab00;
  --srns-color-blue: #007aff;
  --srns-color-minttea: #cafacb;
  --srns-color-mental: #ae4fdd;
  --srns-color-nutrition: #225559;
  --srns-color-purple-10: #031400;
  --srns-color-purple-08: #083400;
  --srns-color-purple-06: #365a2f;
  --srns-color-purple-04: #668160;
  --srns-color-purple-02: #f2f4f2;
  --srns-color-coral-10: #d01406;
  --srns-color-coral-08: #ff5630;
  --srns-color-coral-06: #ffc0a8;
  --srns-color-coral-04: #ffe2d4;
  --srns-color-coral-02: #ffe2d4;
}

.srns-serenis-light {
  --srns-color-grey-10: #ffffff;
  --srns-color-grey-11: #5d5d98;
  --srns-color-grey-20: #f6f7fd;
  --srns-color-grey-30: #ecedf9;
  --srns-color-grey-40: #d7d7ea;
  --srns-color-grey-50: #a7a7d2;
  --srns-color-grey-60: #8686c1;
  --srns-color-grey-70: #7a7aae;
  --srns-color-grey-80: #5d5d98;
  --srns-color-grey-90: #3e3e79;
  --srns-color-grey-100: #2d2d58;
  --srns-color-grey-110: #1c1c36;
  --srns-color-grey-120: #0b0b1e;
  --srns-color-grey-08: #8686c1;
  --srns-color-grey-06: #a7a7d2;
  --srns-color-grey-04: #d7d7ea;
  --srns-color-grey-02: #ecedf9;
  --srns-color-lighter: #ffffff;
  --srns-color-darker: #1c1c36;
  --srns-color-neutral-10: #ffffff;
  --srns-color-neutral-20: #f6f7fd;
  --srns-color-neutral-30: #ecedf9;
  --srns-color-neutral-40: #d7d7ea;
  --srns-color-neutral-50: #a7a7d2;
  --srns-color-neutral-60: #8686c1;
  --srns-color-neutral-70: #7a7aae;
  --srns-color-neutral-80: #5d5d98;
  --srns-color-neutral-90: #3e3e79;
  --srns-color-neutral-100: #2d2d58;
  --srns-color-neutral-110: #1c1c36;
  --srns-color-neutral-120: #0b0b1e;
  --srns-color-accent-10: #ffe9fd;
  --srns-color-accent-20: #ffd1fb;
  --srns-color-accent-30: #fea7f9;
  --srns-color-accent-40: #fd86f6;
  --srns-color-accent-50: #fc8dfe;
  --srns-color-accent-60: #e569dc;
  --srns-color-accent-70: #ca50b7;
  --srns-color-accent-80: #a83c94;
  --srns-color-primary-10: #ebe9fe;
  --srns-color-primary-20: #d9d6fe;
  --srns-color-primary-30: #bdb5fd;
  --srns-color-primary-40: #9d8cf9;
  --srns-color-primary-50: #7a59f5;
  --srns-color-primary-60: #6b3bec;
  --srns-color-primary-70: #5d29d8;
  --srns-color-primary-80: #4d22b5;
  --srns-color-green-10: #227251;
  --srns-color-green-20: #c8eede;
  --srns-color-green-40: #7ad7af;
  --srns-color-green-60: #419f77;
  --srns-color-green-80: #227251;
  --srns-color-green-08: #419f77;
  --srns-color-green-06: #7ad7af;
  --srns-color-green-04: #c8eede;
  --srns-color-green-02: #c8eede;
  --srns-color-yellow-10: #d05e06;
  --srns-color-yellow-20: #ffdf99;
  --srns-color-yellow-40: #ffca5c;
  --srns-color-yellow-60: #ffab00;
  --srns-color-yellow-80: #d05e06;
  --srns-color-yellow-08: #ffab00;
  --srns-color-yellow-06: #ffca5c;
  --srns-color-yellow-04: #ffdf99;
  --srns-color-yellow-02: #ffdf99;
  --srns-color-blue-20: #cce7ff;
  --srns-color-blue-40: #66b8ff;
  --srns-color-blue-60: #007aff;
  --srns-color-blue-80: #004299;
  --srns-color-red-20: #ffe2d4;
  --srns-color-red-40: #ffc0a8;
  --srns-color-red-60: #ff5630;
  --srns-color-red-80: #d01406;
  --srns-elevation-xxs: inset 0 0.5px 0 0 rgb(from #5d29d8 r g b / 24%),
    inset 0 -0.5px 0 0 rgb(from #5d29d8 r g b / 32%), 0 0 8px -2px rgb(from #5d29d8 r g b / 8%),
    0 0 2px 0 rgb(from #5d29d8 r g b / 24%), 0 2px 2px -4px rgb(from #5d29d8 r g b / 64%);
  --srns-elevation-xs: inset 0 0.5px 0 0 rgb(from #5d29d8 r g b / 24%),
    inset 0 -0.5px 0 0 rgb(from #5d29d8 r g b / 32%), 0 4px 16px -2px rgb(from #5d29d8 r g b / 8%),
    0 0 2px 0 rgb(from #5d29d8 r g b / 24%), 0 2px 8px -4px rgb(from #5d29d8 r g b / 32%);
  --srns-elevation-sm: inset 0 0.5px 0 0 rgb(from #5d29d8 r g b / 24%),
    inset 0 -0.5px 0 0 rgb(from #5d29d8 r g b / 32%), 0 0 16px -2px rgb(from #5d29d8 r g b / 8%),
    0 0 8px -2px rgb(from #5d29d8 r g b / 24%), 0 12px 8px -12px rgb(from #5d29d8 r g b / 24%);
  --srns-elevation-md: inset 0 0.5px 0 0 rgb(from #5d29d8 r g b / 24%),
    inset 0 -0.5px 0 0 rgb(from #5d29d8 r g b / 32%), 0 0 16px -2px rgb(from #5d29d8 r g b / 8%),
    0 0 16px -4px rgb(from #5d29d8 r g b / 24%), 0 16px 12px -16px rgb(from #5d29d8 r g b / 16%);
  --srns-elevation-lg: inset 0 0.5px 0 0 rgb(from #5d29d8 r g b / 24%),
    inset 0 -0.5px 0 0 rgb(from #5d29d8 r g b / 32%), 0 0 16px -2px rgb(from #5d29d8 r g b / 8%),
    0 0 24px -4px rgb(from #5d29d8 r g b / 16%), 0 16px 24px -16px rgb(from #5d29d8 r g b / 16%);
  --srns-elevation-xl: inset 0 0.5px 0 0 rgb(from #5d29d8 r g b / 24%),
    inset 0 -0.5px 0 0 rgb(from #5d29d8 r g b / 32%), 0 0 16px -2px rgb(from #5d29d8 r g b / 8%),
    0 0 32px -8px rgb(from #5d29d8 r g b / 16%), 0 32px 32px -24px rgb(from #5d29d8 r g b / 16%);
  --srns-elevation-special: 0 0 8px 0 #7a59f5, 0 0 0 4px rgb(from #7a59f5 r g b / 8%),
    0 0 0 8px rgb(from #7a59f5 r g b / 8%);
  --srns-color-minttea-20: #cafacb;
  --srns-color-minttea-40: #179a92;
  --srns-color-minttea-60: #003b2e;
  --srns-color-minttea-80: #061a1d;
  --srns-color-mental-20: #f1d6ff;
  --srns-color-mental-40: #ce8ef2;
  --srns-color-mental-60: #ae4fdd;
  --srns-color-mental-80: #7a2c9d;
  --srns-color-nutrition-20: #ccf5e0;
  --srns-color-nutrition-40: #83a8a4;
  --srns-color-nutrition-60: #225559;
  --srns-color-nutrition-80: #1f4042;
  --srns-color-accent: #fc8dfe;
  --srns-color-primary: #7a59f5;
  --srns-color-white: #ffffff;
  --srns-color-black: #0b0b1e;
  --srns-color-transparent: transparent;
  --srns-color-success: #419f77;
  --srns-color-info: #007aff;
  --srns-color-warning: #ffab00;
  --srns-color-error: #ff5630;
  --srns-color-red: #ff5630;
  --srns-color-green: #419f77;
  --srns-color-yellow: #ffab00;
  --srns-color-blue: #007aff;
  --srns-color-minttea: #cafacb;
  --srns-color-mental: #ae4fdd;
  --srns-color-nutrition: #225559;
  --srns-color-purple-10: #4d22b5;
  --srns-color-purple-08: #7a59f5;
  --srns-color-purple-06: #9d8cf9;
  --srns-color-purple-04: #bdb5fd;
  --srns-color-purple-02: #ebe9fe;
  --srns-color-coral-10: #d01406;
  --srns-color-coral-08: #ff5630;
  --srns-color-coral-06: #ffc0a8;
  --srns-color-coral-04: #ffe2d4;
  --srns-color-coral-02: #ffe2d4;
}

.srns-serenis-dark {
  --srns-color-grey-10: #ffffff;
  --srns-color-grey-11: #5d5d98;
  --srns-color-grey-20: #f6f7fd;
  --srns-color-grey-30: #ecedf9;
  --srns-color-grey-40: #d7d7ea;
  --srns-color-grey-50: #a7a7d2;
  --srns-color-grey-60: #8686c1;
  --srns-color-grey-70: #7a7aae;
  --srns-color-grey-80: #5d5d98;
  --srns-color-grey-90: #3e3e79;
  --srns-color-grey-100: #2d2d58;
  --srns-color-grey-110: #1c1c36;
  --srns-color-grey-120: #0b0b1e;
  --srns-color-grey-08: #8686c1;
  --srns-color-grey-06: #a7a7d2;
  --srns-color-grey-04: #d7d7ea;
  --srns-color-grey-02: #ecedf9;
  --srns-color-lighter: #1c1c36;
  --srns-color-darker: #ffffff;
  --srns-color-neutral-10: #0b0b1e;
  --srns-color-neutral-20: #1c1c36;
  --srns-color-neutral-30: #2d2d58;
  --srns-color-neutral-40: #3e3e79;
  --srns-color-neutral-50: #5d5d98;
  --srns-color-neutral-60: #7a7aae;
  --srns-color-neutral-70: #8686c1;
  --srns-color-neutral-80: #a7a7d2;
  --srns-color-neutral-90: #d7d7ea;
  --srns-color-neutral-100: #ecedf9;
  --srns-color-neutral-110: #f6f7fd;
  --srns-color-neutral-120: #ffffff;
  --srns-color-accent-10: #ffe8fa;
  --srns-color-accent-20: #ffd4f5;
  --srns-color-accent-30: #ffb1f1;
  --srns-color-accent-40: #ff86e8;
  --srns-color-accent-50: #fc56d8;
  --srns-color-accent-60: #e848c1;
  --srns-color-accent-70: #c6349d;
  --srns-color-accent-80: #a6297f;
  --srns-color-primary-10: #eae8ff;
  --srns-color-primary-20: #d7d4ff;
  --srns-color-primary-30: #bbb1ff;
  --srns-color-primary-40: #9985ff;
  --srns-color-primary-50: #6a42ff;
  --srns-color-primary-60: #6630f7;
  --srns-color-primary-70: #591ee3;
  --srns-color-primary-80: #4918bf;
  --srns-color-green-10: #117363;
  --srns-color-green-20: #c8eee3;
  --srns-color-green-40: #7ad7bd;
  --srns-color-green-60: #25a27f;
  --srns-color-green-80: #117363;
  --srns-color-green-08: #25a27f;
  --srns-color-green-06: #7ad7bd;
  --srns-color-green-04: #c8eee3;
  --srns-color-green-02: #c8eee3;
  --srns-color-yellow-10: #c66606;
  --srns-color-yellow-20: #ffe599;
  --srns-color-yellow-40: #ffd65c;
  --srns-color-yellow-60: #ffbf00;
  --srns-color-yellow-80: #c66606;
  --srns-color-yellow-08: #ffbf00;
  --srns-color-yellow-06: #ffd65c;
  --srns-color-yellow-04: #ffe599;
  --srns-color-yellow-02: #ffe599;
  --srns-color-blue-20: #dbedff;
  --srns-color-blue-40: #70c6ff;
  --srns-color-blue-60: #0a84ff;
  --srns-color-blue-80: #0a4299;
  --srns-color-red-20: #ffd9d6;
  --srns-color-red-40: #ffa099;
  --srns-color-red-60: #ff291a;
  --srns-color-red-80: #be2323;
  --srns-elevation-xxs: inset 0 0.5px 0 0 #5d5d98, inset 0 -0.5px 0 0 #0b0b1e,
    0 0 8px -2px rgb(from #0b0b1e r g b / 32%), 0 0 2px 0 rgb(from #0b0b1e r g b / 56%),
    0 2px 2px -4px rgb(from #0b0b1e r g b / 88%);
  --srns-elevation-xs: inset 0 0.5px 0 0 #5d5d98, inset 0 -0.5px 0 0 #0b0b1e,
    0 4px 16px -2px rgb(from #0b0b1e r g b / 32%), 0 0 2px 0 rgb(from #0b0b1e r g b / 56%),
    0 2px 8px -4px rgb(from #0b0b1e r g b / 64%);
  --srns-elevation-sm: inset 0 0.5px 0 0 #5d5d98, inset 0 -0.5px 0 0 #0b0b1e,
    0 0 16px -2px rgb(from #0b0b1e r g b / 32%), 0 0 8px -2px rgb(from #0b0b1e r g b / 56%),
    0 12px 8px -12px rgb(from #0b0b1e r g b / 56%);
  --srns-elevation-md: inset 0 0.5px 0 0 #5d5d98, inset 0 -0.5px 0 0 #0b0b1e,
    0 0 16px -2px rgb(from #0b0b1e r g b / 32%), 0 0 16px -4px rgb(from #0b0b1e r g b / 56%),
    0 16px 12px -16px rgb(from #0b0b1e r g b / 48%);
  --srns-elevation-lg: inset 0 0.5px 0 0 #5d5d98, inset 0 -0.5px 0 0 #0b0b1e,
    0 0 16px -2px rgb(from #0b0b1e r g b / 32%), 0 0 24px -4px rgb(from #0b0b1e r g b / 48%),
    0 16px 24px -16px rgb(from #0b0b1e r g b / 48%);
  --srns-elevation-xl: inset 0 0.5px 0 0 #5d5d98, inset 0 -0.5px 0 0 #0b0b1e,
    0 0 16px -2px rgb(from #0b0b1e r g b / 32%), 0 0 32px -8px rgb(from #0b0b1e r g b / 32%),
    0 32px 32px -24px rgb(from #0b0b1e r g b / 48%);
  --srns-elevation-special: 0 0 8px 0 #6a42ff, 0 0 0 4px rgb(from #6a42ff r g b / 8%),
    0 0 0 8px rgb(from #6a42ff r g b / 8%);
  --srns-color-minttea-20: #cafacb;
  --srns-color-minttea-40: #179a92;
  --srns-color-minttea-60: #003b2e;
  --srns-color-minttea-80: #061a1d;
  --srns-color-mental-20: #f1d6ff;
  --srns-color-mental-40: #ce8ef2;
  --srns-color-mental-60: #ae4fdd;
  --srns-color-mental-80: #7a2c9d;
  --srns-color-nutrition-20: #ccf5e0;
  --srns-color-nutrition-40: #83a8a4;
  --srns-color-nutrition-60: #225559;
  --srns-color-nutrition-80: #1f4042;
  --srns-color-accent: #fc56d8;
  --srns-color-primary: #6a42ff;
  --srns-color-white: #ffffff;
  --srns-color-black: #0b0b1e;
  --srns-color-transparent: transparent;
  --srns-color-success: #25a27f;
  --srns-color-info: #0a84ff;
  --srns-color-warning: #ffbf00;
  --srns-color-error: #ff291a;
  --srns-color-red: #ff291a;
  --srns-color-green: #25a27f;
  --srns-color-yellow: #ffbf00;
  --srns-color-blue: #0a84ff;
  --srns-color-minttea: #cafacb;
  --srns-color-mental: #ae4fdd;
  --srns-color-nutrition: #225559;
  --srns-color-purple-10: #4918bf;
  --srns-color-purple-08: #6a42ff;
  --srns-color-purple-06: #9985ff;
  --srns-color-purple-04: #bbb1ff;
  --srns-color-purple-02: #eae8ff;
  --srns-color-coral-10: #be2323;
  --srns-color-coral-08: #ff291a;
  --srns-color-coral-06: #ffa099;
  --srns-color-coral-04: #ffd9d6;
  --srns-color-coral-02: #ffd9d6;
}

@media (min-width: 601px) {
  :root {
    --srns-typography-public-h0-line-height: 64px;
    --srns-typography-public-h0-font-size: 64px;
    --srns-typography-public-h0-letter-spacing: calc(128px / 100);
    --srns-typography-public-h1-line-height: 40px;
    --srns-typography-public-h1-font-size: 40px;
    --srns-typography-public-h1-letter-spacing: 0.8px;
    --srns-typography-public-h2-line-height: 32px;
    --srns-typography-public-h2-font-size: 32px;
    --srns-typography-public-h2-letter-spacing: 0.64px;
  }
}

@media (min-width: 1025px) {
  :root {
    --srns-typography-public-h0-line-height: 72px;
    --srns-typography-public-h0-font-size: 72px;
    --srns-typography-public-h0-letter-spacing: calc(144px / 100);
    --srns-typography-public-h1-line-height: 48px;
    --srns-typography-public-h1-font-size: 48px;
    --srns-typography-public-h1-letter-spacing: 0.96px;
    --srns-typography-public-h2-line-height: 40px;
    --srns-typography-public-h2-font-size: 40px;
    --srns-typography-public-h2-letter-spacing: 0.8px;
  }
}
